import React, { createContext, useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import { LocalStorage, LocalStorageKeys } from "../utils";
import { useLocation } from "@reach/router"

export const AuthContext = createContext({})

const AuthProvider = ({ children }) => {
    const info = LocalStorage.get(LocalStorageKeys.USER_DETAILS)
    const [user, setUser] = useState(info ? JSON.parse(info) : null)

    useEffect(() => {
        // firebase.auth().onAuthStateChanged(user => setUser(user))
        const location = useLocation()
        const queryParams = new URLSearchParams(location.search)
        const userparam = queryParams.get("user")
        setUser(userparam)
    }, [])

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => setUser(user))
    }, [])

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;